import React from "react";
import { useForm } from "react-hook-form";

const Contact = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data, e) => {
    e.target.reset();
    console("Message submited: " + JSON.stringify(data));
  };

  return (
    <>
      <p className="contact-icons">
        <a className="px-2" href="mailto:travis.j.twilt@gmail.com"><i className="ti-email mx-3"></i></a>
        <a href="https://www.linkedin.com/in/travis-twilt-69440676/"><i className="ti-linkedin mx-3"></i></a>
      </p>
    </>
  );
};

export default Contact;
