import React from "react";
import Header from "../../components/header/Header";
import Slider from "../../components/slider/Slider";
import About from "../../components/about/About";
import Service from "../../components/service/Service";
import Skills from "../../components/skills/Skills";
import Portfolio from "../../components/portfolio/Portfolio";
import Testimonial from "../../components/testimonial/Testimonial";
import Blog from "../../components/blog/Blog";
import Contact from "../../components/contact/Contact";
import Footer from "../../components/footer/Footer";
import useDocumentTitle from "../../components/useDocumentTitle";

const HomeOne = () => {
  useDocumentTitle("Travis Twilt");
  return (
    <>
      <Header />
      {/* End Header Section */}
      <Slider />
      {/* End Banner Section */}


      {/* Skills */}
      <section id="skills" className="section services-section gray-bg">
        <div className="container">
          <div className="row justify-content-center section-title text-center">
            <div className="col-lg-7">
              <h3 className="font-alt">Skills</h3>
              <p>
                My lifelong passion is making cool things with computers. Over the years, I have accumulated a great deal of expertise in a huge range of software disciplines. I've developed web applications, made video games, managed software projects, worked in visual effects, produced digital art, and so much more. My top skills are listed below.
              </p>
            </div>
          </div>
          {/* End .row */}
          <Service />
        </div>
      </section>
      {/* End Services */}


      {/*  Experience */}
      <section id="experience" className="section skill-section">
        <div className="container">
          <div className="row justify-content-center section-title text-center">
            <h3 className="font-alt">Experience</h3>
            <p>
              I have over 8 years of professional experience as a software developer and many more than that as a hobbyist game developer. My professional career is outlined below.
            </p>
          </div>
          <Skills />
        </div>
      </section>
      {/* End Experience */}


      {/* Portfolio */}
      <section id="work" className="section gray-bg">
        <div className="container">
          <div className="row justify-content-center section-title text-center">
            <h3 className="font-alt">Projects and Awards</h3>
            <p>
              Here are some of the projects and awards that I'm most proud of.
            </p>
          </div>
          {/* End .row */}
          <Portfolio />
        </div>
      </section>
      {/* End Portfolio */}


      {/* Contact */}
      <section id="contact" className="section after-left-section">
        <div className="container">

          <div className="row justify-content-center section-title text-center">
            <h3 className="font-alt">Contact</h3>
            <p>
              Feel free to send me an email or connect with me on LinkedIn.
            </p>
          </div>

          <div className="row align-items-center">
            <div className="col-lg-12">

                {/* End .sm-title */}
                <Contact />
              </div>
            </div>
            {/* End .col */}
        </div>
      </section>
      {/* End Contact */}

      {/* Footer */}
      <footer className="footer white">
        <div className="container">
          <Footer />
        </div>
      </footer>
      {/*  End Footer */}
    </>
  );
};

export default HomeOne;
