import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import SimpleReactLightbox from "simple-react-lightbox";
import { SRLWrapper } from "simple-react-lightbox";

const Portfolio = () => {
  return (
    <SimpleReactLightbox>
      <div className="positon-relative">
        <div className="portfolio-filter-01">
          <Tabs>
            <TabList className="filter d-flex justify-content-center">
              <Tab>All</Tab>
              <Tab>Web Apps</Tab>
              <Tab>Video Games</Tab>
              <Tab>Digital Art</Tab>
              <Tab>Awards</Tab>
            </TabList>
            {/* End tablist */}

            <SRLWrapper>
              <TabPanel>
                <div className="portfolio-content row lightbox-gallery">
                  

                  <div className="col-sm-6 col-lg-4 grid-item product">
                    <div className="portfolio-box-01">
                      <div className="portfolio-img">
                        <div className="portfolio-info">
                          <h5>Vitrea Intelligence</h5>
                          <span>Full Stack Development and Design</span>
                        </div>
                        {/* End .portfolio-info */}
                        <a href="img/portfolio/3.jpg" className="gallery-link">
                          <img src="img/portfolio/3.jpg" 
                          alt="Vitrea Intelligence" />
                          <div className="portfolio-icon">
                            <span className="ti-plus"></span>
                          </div>
                          {/* End .portfolio-icon */}
                        </a>
                        {/* End gallery link */}
                      </div>
                    </div>
                  </div>
                  {/* grid item  */}


                  <div className="col-sm-6 col-lg-4 grid-item product">
                    <div className="portfolio-box-01">
                      <div className="portfolio-img">
                        <div className="portfolio-info">
                          <h5>iMD Cloud</h5>
                          <span>Full Stack Development and Design</span>
                        </div>
                        {/* End .portfolio-info */}
                        <a href="img/portfolio/1.jpg" className="gallery-link">
                          <img src="img/portfolio/1.jpg" 
                          alt="iMD Cloud" />
                          <div className="portfolio-icon">
                            <span className="ti-plus"></span>
                          </div>
                          {/* End .portfolio-icon */}
                        </a>
                        {/* End gallery link */}
                      </div>
                    </div>
                  </div>
                  {/* grid item  */}


                  <div className="col-sm-6 col-lg-4 grid-item product">
                    <div className="portfolio-box-01">
                      <div className="portfolio-img">
                        <div className="portfolio-info">
                          <h5>Flying Zombie Invasion</h5>
                          <span>Design / Graphics / Programming</span>
                        </div>
                        {/* End .portfolio-info */}
                        <a href="img/portfolio/4.jpg" className="gallery-link">
                          <img src="img/portfolio/4.jpg" 
                          alt="Flying Zombie Invasion" />
                          <div className="portfolio-icon">
                            <span className="ti-plus"></span>
                          </div>
                          {/* End .portfolio-icon */}
                        </a>
                        {/* End gallery link */}
                      </div>
                    </div>
                  </div>
                  {/* grid item  */}


                  <div className="col-sm-6 col-lg-4 grid-item product">
                    <div className="portfolio-box-01">
                      <div className="portfolio-img">
                        <div className="portfolio-info">
                          <h5>Chime Spirits</h5>
                          <span>Design / Graphics / Programming</span>
                        </div>
                        {/* End .portfolio-info */}
                        <a href="img/portfolio/5.jpg" className="gallery-link">
                          <img
                            src="img/portfolio/5.jpg"
                            alt="Web Application"
                          />
                          <div className="portfolio-icon">
                            <span className="ti-plus"></span>
                          </div>
                          {/* End .portfolio-icon */}
                        </a>
                        {/* End gallery link */}
                      </div>
                    </div>
                  </div>
                  {/* grid item  */}


                  <div className="col-sm-6 col-lg-4 grid-item product">
                    <div className="portfolio-box-01">
                      <div className="portfolio-img">
                        <div className="portfolio-info">
                          <h5>Dino Dan</h5>
                          <span>Digital Compositing</span>
                        </div>
                        {/* End .portfolio-info */}
                        <a href="img/portfolio/2.jpg" className="gallery-link">
                          <img src="img/portfolio/2.jpg" 
                          alt="Dino Dan" />
                          <div className="portfolio-icon">
                            <span className="ti-plus"></span>
                          </div>
                          {/* End .portfolio-icon */}
                        </a>
                        {/* End gallery link */}
                      </div>
                    </div>
                  </div>
                  {/* grid item  */}


                  <div className="col-sm-6 col-lg-4 grid-item product">
                    <div className="portfolio-box-01">
                      <div className="portfolio-img">
                        <div className="portfolio-info">
                          <h5>Digital Art</h5>
                          <span>Adobe Photoshop</span>
                        </div>
                        {/* End .portfolio-info */}
                        <a href="img/portfolio/7.jpg" className="gallery-link">
                          <img
                            src="img/portfolio/7.jpg"
                            alt="Photos and Graphics"
                          />
                          <div className="portfolio-icon">
                            <span className="ti-plus"></span>
                          </div>
                          {/* End .portfolio-icon */}
                        </a>
                        {/* End gallery link */}
                      </div>
                    </div>
                  </div>
                  {/* grid item  */}


                  <div className="col-sm-6 col-lg-4 grid-item product">
                    <div className="portfolio-box-01">
                      <div className="portfolio-img">
                        <div className="portfolio-info">
                          <h5>Photography</h5>
                          <span>Canon SLR</span>
                        </div>
                        {/* End .portfolio-info */}
                        <a href="img/portfolio/8.jpg" className="gallery-link">
                          <img
                            src="img/portfolio/8.jpg"
                            alt="Photography"
                          />
                          <div className="portfolio-icon">
                            <span className="ti-plus"></span>
                          </div>
                          {/* End .portfolio-icon */}
                        </a>
                        {/* End gallery link */}
                      </div>
                    </div>
                  </div>
                  {/* grid item  */}


                  <div className="col-sm-6 col-lg-4 grid-item product">
                    <div className="portfolio-box-01">
                      <div className="portfolio-img">
                        <div className="portfolio-info">
                          <h5>Skills Ontario</h5>
                          <span>Gold Medal - Character Animation</span>
                        </div>
                        {/* End .portfolio-info */}
                        <a href="img/portfolio/6.jpg" className="gallery-link">
                          <img
                            src="img/portfolio/6.jpg"
                            alt="Skills Ontario - Gold Medal"
                          />
                          <div className="portfolio-icon">
                            <span className="ti-plus"></span>
                          </div>
                          {/* End .portfolio-icon */}
                        </a>
                        {/* End gallery link */}
                      </div>
                    </div>
                  </div>
                  {/* grid item  */}


                  <div className="col-sm-6 col-lg-4 grid-item product">
                    <div className="portfolio-box-01">
                      <div className="portfolio-img">
                        <div className="portfolio-info">
                          <h5>iMD Health</h5>
                          <span>Employee of the Year</span>
                        </div>
                        {/* End .portfolio-info */}
                        <a href="img/portfolio/9.jpg" className="gallery-link">
                          <img
                            src="img/portfolio/9.jpg"
                            alt="Employee of the Year"
                          />
                          <div className="portfolio-icon">
                            <span className="ti-plus"></span>
                          </div>
                          {/* End .portfolio-icon */}
                        </a>
                        {/* End gallery link */}
                      </div>
                    </div>
                  </div>
                  {/* grid item  */}

                </div>
                {/* End list wrapper */}
              </TabPanel>

              <TabPanel>
                <div className="portfolio-content row lightbox-gallery">
                  <div className="col-sm-6 col-lg-4 grid-item product">
                    <div className="portfolio-box-01">
                      <div className="portfolio-img">
                        <div className="portfolio-info">
                          <h5>Vitrea Intelligence</h5>
                          <span>Full Stack Development and Design</span>
                        </div>
                        {/* End .portfolio-info */}
                        <a href="img/portfolio/3.jpg" className="gallery-link">
                          <img src="img/portfolio/3.jpg" 
                          alt="Vitrea Intelligence" />
                          <div className="portfolio-icon">
                            <span className="ti-plus"></span>
                          </div>
                          {/* End .portfolio-icon */}
                        </a>
                        {/* End gallery link */}
                      </div>
                    </div>
                  </div>
                  {/* grid item  */}


                  <div className="col-sm-6 col-lg-4 grid-item product">
                    <div className="portfolio-box-01">
                      <div className="portfolio-img">
                        <div className="portfolio-info">
                          <h5>iMD Cloud</h5>
                          <span>Full Stack Development and Design</span>
                        </div>
                        {/* End .portfolio-info */}
                        <a href="img/portfolio/1.jpg" className="gallery-link">
                          <img src="img/portfolio/1.jpg" 
                          alt="iMD Cloud" />
                          <div className="portfolio-icon">
                            <span className="ti-plus"></span>
                          </div>
                          {/* End .portfolio-icon */}
                        </a>
                        {/* End gallery link */}
                      </div>
                    </div>
                  </div>
                  {/* grid item  */}
                </div>
              </TabPanel>

              <TabPanel>
                <div className="portfolio-content row lightbox-gallery">
                  <div className="col-sm-6 col-lg-4 grid-item product">
                    <div className="portfolio-box-01">
                      <div className="portfolio-img">
                        <div className="portfolio-info">
                          <h5>Flying Zombie Invasion</h5>
                          <span>Design / Graphics / Programming</span>
                        </div>
                        {/* End .portfolio-info */}
                        <a href="img/portfolio/4.jpg" className="gallery-link">
                          <img src="img/portfolio/4.jpg" 
                          alt="Flying Zombie Invasion" />
                          <div className="portfolio-icon">
                            <span className="ti-plus"></span>
                          </div>
                          {/* End .portfolio-icon */}
                        </a>
                        {/* End gallery link */}
                      </div>
                    </div>
                  </div>
                  {/* grid item  */}


                  <div className="col-sm-6 col-lg-4 grid-item product">
                    <div className="portfolio-box-01">
                      <div className="portfolio-img">
                        <div className="portfolio-info">
                          <h5>Chime Spirits</h5>
                          <span>Design / Graphics / Programming</span>
                        </div>
                        {/* End .portfolio-info */}
                        <a href="img/portfolio/5.jpg" className="gallery-link">
                          <img
                            src="img/portfolio/5.jpg"
                            alt="Web Application"
                          />
                          <div className="portfolio-icon">
                            <span className="ti-plus"></span>
                          </div>
                          {/* End .portfolio-icon */}
                        </a>
                        {/* End gallery link */}
                      </div>
                    </div>
                  </div>
                  {/* grid item  */}
                </div>
              </TabPanel>

              <TabPanel>
                <div className="portfolio-content row lightbox-gallery">
                  <div className="col-sm-6 col-lg-4 grid-item product">
                    <div className="portfolio-box-01">
                      <div className="portfolio-img">
                        <div className="portfolio-info">
                          <h5>Dino Dan</h5>
                          <span>Digital Compositing</span>
                        </div>
                        {/* End .portfolio-info */}
                        <a href="img/portfolio/2.jpg" className="gallery-link">
                          <img src="img/portfolio/2.jpg" 
                          alt="Dino Dan" />
                          <div className="portfolio-icon">
                            <span className="ti-plus"></span>
                          </div>
                          {/* End .portfolio-icon */}
                        </a>
                        {/* End gallery link */}
                      </div>
                    </div>
                  </div>
                  {/* grid item  */}


                  <div className="col-sm-6 col-lg-4 grid-item product">
                    <div className="portfolio-box-01">
                      <div className="portfolio-img">
                        <div className="portfolio-info">
                          <h5>Digital Art</h5>
                          <span>Adobe Photoshop</span>
                        </div>
                        {/* End .portfolio-info */}
                        <a href="img/portfolio/7.jpg" className="gallery-link">
                          <img
                            src="img/portfolio/7.jpg"
                            alt="Photos and Graphics"
                          />
                          <div className="portfolio-icon">
                            <span className="ti-plus"></span>
                          </div>
                          {/* End .portfolio-icon */}
                        </a>
                        {/* End gallery link */}
                      </div>
                    </div>
                  </div>
                  {/* grid item  */}


                  <div className="col-sm-6 col-lg-4 grid-item product">
                    <div className="portfolio-box-01">
                      <div className="portfolio-img">
                        <div className="portfolio-info">
                          <h5>Photography</h5>
                          <span>Canon SLR</span>
                        </div>
                        {/* End .portfolio-info */}
                        <a href="img/portfolio/8.jpg" className="gallery-link">
                          <img
                            src="img/portfolio/8.jpg"
                            alt="Photography"
                          />
                          <div className="portfolio-icon">
                            <span className="ti-plus"></span>
                          </div>
                          {/* End .portfolio-icon */}
                        </a>
                        {/* End gallery link */}
                      </div>
                    </div>
                  </div>
                  {/* grid item  */}
                </div>
                {/* End list wrapper */}
              </TabPanel>

              <TabPanel>
                <div className="portfolio-content row lightbox-gallery">
                  <div className="col-sm-6 col-lg-4 grid-item product">
                    <div className="portfolio-box-01">
                      <div className="portfolio-img">
                        <div className="portfolio-info">
                          <h5>Skills Ontario</h5>
                          <span>Gold Medal - Character Animation</span>
                        </div>
                        {/* End .portfolio-info */}
                        <a href="img/portfolio/6.jpg" className="gallery-link">
                          <img
                            src="img/portfolio/6.jpg"
                            alt="Skills Ontario - Gold Medal"
                          />
                          <div className="portfolio-icon">
                            <span className="ti-plus"></span>
                          </div>
                          {/* End .portfolio-icon */}
                        </a>
                        {/* End gallery link */}
                      </div>
                    </div>
                  </div>
                  {/* grid item  */}


                  <div className="col-sm-6 col-lg-4 grid-item product">
                    <div className="portfolio-box-01">
                      <div className="portfolio-img">
                        <div className="portfolio-info">
                          <h5>iMD Health</h5>
                          <span>Employee of the Year</span>
                        </div>
                        {/* End .portfolio-info */}
                        <a href="img/portfolio/9.jpg" className="gallery-link">
                          <img
                            src="img/portfolio/9.jpg"
                            alt="Employee of the Year"
                          />
                          <div className="portfolio-icon">
                            <span className="ti-plus"></span>
                          </div>
                          {/* End .portfolio-icon */}
                        </a>
                        {/* End gallery link */}
                      </div>
                    </div>
                  </div>
                  {/* grid item  */}
                </div>
                {/* End list wrapper */}
              </TabPanel>
            </SRLWrapper>
            {/* End tabpanel */}
          </Tabs>
        </div>
      </div>
    </SimpleReactLightbox>
  );
};

export default Portfolio;
