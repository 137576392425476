import React from "react";

const ServiceContent = [
  {
    icon: "ti-desktop",
    title: "Front End Web Development",
    descriptions: `HTML5 / CSS3 / JavaScript / Angular`,
  },
  {
    icon: "ti-harddrives",
    title: "Back End Web Development",
    descriptions: `Node.js / Ruby on Rails / PHP`,
  },
  {
    icon: "ti-server",
    title: "Database Development",
    descriptions: `PostgreSQL / MySQL`,
  },
  {
    icon: "ti-ruler-pencil",
    title: "UI / UX Design",
    descriptions: `Adobe XD`,
  },
  {
    icon: "ti-upload",
    title: "Version Control",
    descriptions: `Git / BitBucket / SourceTree`,
  },
  {
    icon: "ti-cloud",
    title: "Cloud Computing",
    descriptions: `AWS / Google Cloud`,
  },
  {
    icon: "ti-clipboard",
    title: "Project Management",
    descriptions: `Jira / Trello`,
  },
  {
    icon: "ti-game",
    title: "Video Game Development",
    descriptions: `Unreal Engine / C# / Game Maker`,
  },
  {
    icon: "ti-palette",
    title: "Digital Art",
    descriptions: `Adobe Photoshop / Maya / Blender`,
  },
];

export default function Service() {
  return (
    <>
      <div className="row">
        {ServiceContent.map((val, i) => (
          <div className="col-md-6 col-lg-4 my-3" key={i}>
            <div className="feature-box-01">
              <div className="icon">
                <i className={val.icon}></i>
              </div>
              <div className="feature-content">
                <h5>{val.title}</h5>
                <p>{val.descriptions}</p>
              </div>
            </div>
            {/* End .feature-box-01 */}
          </div>
        ))}
      </div>
    </>
  );
}
