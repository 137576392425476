import React from "react";

const Skills = () => {
  return (
    <>
      <div className="row">

        <div className="col-lg-12 my-3">
          <ul className="aducation-box dark-bg">
            <li class="experience-item">
              <span className="theme-bg">Jul 2020 - Present</span>
              <h6>Lead Software Developer</h6>
              <p>Feely Music</p>
              <ul>
                <li class="role-item"><p>Make high-level decisions about technology stack, project management, documentation, testing, security etc.</p></li>
                <li class="role-item"><p>Generate tickets and assign to development staff</p></li>
                <li class="role-item"><p>Conduct meetings and interviews</p></li>
              </ul>
            </li>
            <li class="experience-item">
              <span className="theme-bg">Oct 2018 - Jul 2020</span>
              <h6>Full Stack Software Developer</h6>
              <p>Vital Images</p>
              <ul>
                <li class="role-item"><p>Designed and developed web applications that analyze healthcare data to generate insights and predictions</p></li>
                <li class="role-item"><p>Working at all levels of the stack, I wireframed new features and wrote the front end code, back end code, unit tests, and database migrations to implement.</p></li>
              </ul>
            </li>
            <li class="experience-item">
              <span className="theme-bg">Mar 2011 - Jul 2016</span>
              <h6>Full Stack Software Developer</h6>
              <p>iMD Health</p>
              <ul>
                <li class="role-item"><p>Designed and developed web applications that help doctors visually explain conditions to patients</p></li>
                <li class="role-item"><p>Working at all levels of the stack, I wireframed new features and wrote the front end code, back end code, unit tests, and database migrations to implement.</p></li>
                <li class="role-item"><p>Leveraged previous design expertise to develop marketing creative and software wireframes</p></li>
                <li class="role-item"><p>Employee of the Year 2014</p></li>
              </ul>
            </li>
            <li class="experience-item">
              <span className="theme-bg">Jun 2010 - Feb 2011</span>
              <h6>Digital Compositor</h6>
              <p><b>Optix Digital Pictures</b></p>
              <ul>
                <li class="role-item"><p>Worked on the television show Dino Dan</p></li>
                <li class="role-item"><p>Combined live action footage with CGI elements to create final shots for the show</p></li>
              </ul>
            </li>
            <li class="experience-item">
              <span className="theme-bg">Oct 2009 - Jun 2010</span>
              <h6>Multimedia Associate</h6>
              <p><b>Bell Canada PMP</b></p>
              <ul>
                <li class="role-item"><p>Programmed digital signage for offices using ActionScript</p></li>
                <li class="role-item"><p>Created and rendered 3D models of Bell products</p></li>
                <li class="role-item"><p>Created video promotions using Adobe Premiere and After Effects</p></li>
              </ul>
            </li>
          </ul>
          {/* End  .aducation-box */}
        </div>
        {/* End .col */}

      </div>
    </>
  );
};

export default Skills;
